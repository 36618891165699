import Cookies from 'js-cookie';
import $ from 'jquery';

export function dictToQueryParamString(dict) {
  let searchParams = new URLSearchParams();

  for (let [key, value] of Object.entries(dict)) {
    const resolvedValue = (typeof value === 'function') ? value() : value;
    if (Array.isArray(resolvedValue)) {
      for (const item of resolvedValue) {
        searchParams.append(`${key}[]`, item);
      }
    }
    else {
      searchParams.append(key, resolvedValue);
    }
  }
  return searchParams.toString();
}
export function pascalToSnake(string) {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).substring(1);
}
export function camelToSnake(string) {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}
export function hideFlash(timeout) {
  const flash = query('#vf-admin-flash');
  setTimeout(() => flash.addEventListener('click', () => flash.style.opacity = 0), timeout);
}

export function setAdminLocale() {
  queryAll('a[data-locale]').forEach(link => {
    link.addEventListener('click', () => {
      Cookies.set('locale', link.dataset.locale, { expires: 7, path: '/'});
    });
  });
}

export function highlightDropZones(...drop_zones) {
  // block dropping files everywhere on the page
  // but allow bubbling, so it still gets to the more specific drop zones
  // also the highlighting of drop zones is cleared
  window.addEventListener('drop', event => {
    event.preventDefault()
    drop_zones.forEach(selector => {
      queryAll(selector).forEach(zone => {
        zone.style = '';
      });
    });
  });
  // dragging over must be blocked as well
  // and the visual feedback is changed to indicate no drop allowed
  window.addEventListener('dragover', event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'none';
  });
  // valid drop zones are highlighted
  window.addEventListener('dragenter', () => {
    drop_zones.forEach(selector => {
      queryAll(selector).forEach(zone => {
        zone.style.boxShadow = '0 0 3px gray';
      });
    });
  });
  window.addEventListener('dragend', event => {
    drop_zones.forEach(selector => {
      queryAll(selector).forEach(zone => {
        zone.style = '';
      });
    });
  });
}

export function ckeditorCharCount( id, label_name ) {
  CKEDITOR.on('instanceReady', function(event) {
    const editor = CKEDITOR.instances[id];
    editor.document.on('keyup', function ( event ){
      const l = $('label[for="' + label_name + '"]');
      l.text(l.text().replace(/(.+ )\(\d+\)/, "$1(" + editor.getData().length + ")"));
    });
  });
}
export function displayCharCount( toCount, label_name ) {
  $(toCount).keyup(function() {
    var l = $('label[for="' + label_name + '"]');
    l.text(l.text().replace(/(.+ )\(\d+\)/, "$1(" + $(this).val().length + ")"));
  });
}

export function updatePosition( selector, url ) {
  $(selector).change(function( event ) {
    var value = $(this).find('option:selected').attr('value');

    $.post(url, { data: { id: value } }, function( data ) {
      if (data.status == 'ok') {
          $('#position').val(data.position);
      } else {
          console.log('Some errors encountered: Could not fetch position value.');
      }
    },'json');
  });
}
	
export function modifyWarnings(selector, url) {
  query(selector).addEventListener('click', event => {
    omsFetch({
      serviceUrl: url,
      method: 'POST'
    }).then((response) => {
      if (response.status === 'ok') {
        location.replace('/admin_warnings');
      }
      else {
        console.error('Failed to update warnings')
      }
    }).catch(error => handleServerNetworkError(error));
  });
}

export function handleServerNetworkError(error) {
  if (error instanceof TypeError) {
    showErrorFlash(`Hálózati hiba, szerver nem elérhető: ${error.message}`);
  }
  else {
    showErrorFlash('Szerveroldali hiba történt!');
  }
}

export function updateActiveness( checkboxSelector, resourceName ) {
  queryAll(checkboxSelector).forEach(checkbox => checkbox.addEventListener('change', function(event) {
    const is_checked = checkbox.checked;
    const resource_id = checkbox.value;

    omsFetch({
      serviceUrl: `/products/${resource_id}/`,
      method: 'PATCH',
      params: {
        product: {
          active: is_checked,
        }
      }
    })
  }));
}

export function copyToClipboard( selector ) {
  //create a hidden form to copy from
  $(document).ready(function () {
    const hiddenForm = document.createElement("textarea");
    hiddenForm.style.position = "absolute";
    hiddenForm.style.left = "-9999px";
    hiddenForm.style.top = "0";
    hiddenForm.id = "_hiddenCopyForm";

    document.body.appendChild(hiddenForm);
  });

  // copy the text specified in the 'data-clipboard-text' attribute of the selector
  $(selector).click(function (event) {
    event.preventDefault();
    var copyText = $(event.target).attr("data-clipboard-text");

    // put the text to copy into the hidden form
    var copyForm = $('#_hiddenCopyForm')[0];
    copyForm.textContent = copyText;

    // select it
    var prevFocus = document.activeElement;
    copyForm.focus();
    copyForm.setSelectionRange(0, copyForm.value.length);

    // try to copy it
    var success;
    try {
      success = document.execCommand("copy");
    }
    catch (e) {
      success = false;
    }
    if (!success) {
      //TODO log to console
    }

    // restore original focus
    if (prevFocus && typeof prevFocus.focus === "function") {
      prevFocus.focus();
    }

    // clear the temporary form
    copyForm.textContent = "";
  });
}

export function showPromptOnClick( selector, controlText ) {
  queryAll(selector).forEach(item => item.addEventListener('click', () => {
    const value = prompt('Írd be a \'' + controlText + '\' szót az alábbi mezőbe: ');

    if (value == null || value !== controlText) {
      event.stopPropagation();
      event.preventDefault();
    }
  }));
}

export function addFileUploadHelper(fileInputSelector) {
  query(fileInputSelector).addEventListener('change', event => {
    const input = event.currentTarget;
    const path = input.value;
    const file = path.substring(path.lastIndexOf('\\') + 1);
    const ext = file.substring(file.lastIndexOf('.') + 1);

    document.querySelector('#image_url').value = file;
  });
}

export function htmlEntities( string ) {
  return string.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function christmasOverlay( fadeOutSpeed ) {
  var w,h,
    wrapper = $('#vf-christmas-wrapper'),
    overlay = $('#vf-christmas-overlay');

  if ($.cookie('christmasVisited') != 'true') {
    $(window).on('resize', function() {
      w = $(window).width(),
      h = $(window).height();

      overlay.css({ width: w, height: h });
      wrapper.css('display', 'block');
    });

    $(window).trigger('resize');

    $(document).keyup(function(e) {
      if (e.keyCode == 27) {
          overlay.fadeOut(fadeOutSpeed);
          setCookie();
      }
    });

    $(document).mouseup(function(e) {
      if (!wrapper.is(e.target) && wrapper.has(e.target).length === 0) {
        overlay.fadeOut(fadeOutSpeed);
        setCookie();
      }
    });
  }

  function setCookie() {
    $.cookie('christmasVisited', true, { path: '/' });
  }
}
export function createElementFromString(string) {
  let tempElement = document.createElement('template');
  tempElement.innerHTML = string;
  return tempElement.content.childElementCount === 1 ? tempElement.content.firstElementChild : tempElement.content.children;
}
export function omsFetch({
  serviceUrl,
  method = 'GET',
  cache = 'no-cache',
  params = {},
  contentType = 'application/json',
  accepts = 'application/json;charset=utf-8'
}) {
  const csrfSelector = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfSelector ? csrfSelector.content : null;

  let headers = (method !== 'GET' && csrfToken) ? { 'X-CSRF-Token': csrfToken } : {};
  headers['Accept'] = accepts;
  let resolvedUrl = typeof serviceUrl === 'function' ? serviceUrl() : serviceUrl;

  if (method === 'GET' || method === 'DELETE') {
    const paramString = dictToQueryParamString(params);
    if (paramString) {
      resolvedUrl = `${resolvedUrl}?${paramString}`;
    }
  }

  let body = null;
  // if the params is not empty and the method is POST, it will be sent in the body, so set the Content-Type header
  if ((method === 'POST' || method === 'PATCH') && params !== {}) {
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    switch(contentType) {
      case 'application/json':
        body = JSON.stringify(params);
        break;
      default:
        body = params;
        break;
    }
  }

  return fetch(resolvedUrl, {
    method: method,
    cache: cache,
    headers: headers,
    body: body
  }).then(response => {
    if (!response.ok) {
      throw response;
    }
    if (accepts === 'application/json;charset=utf-8') {
      return response.json();
    }
    return response.text();
  });
}
